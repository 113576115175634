import React from 'react';

const pastHonereesLogos = [
  {
    src: 'https://assets.mansueto.com/uploads/aflac.svg',
    alt: 'Aflac'
  },
  {
    src: 'https://assets.mansueto.com/uploads/ally_financial.svg',
    alt: 'Ally Financial'
  },
  {
    src: 'https://assets.mansueto.com/uploads/amex.svg',
    alt: 'American Express'
  },
  {
    src: 'https://assets.mansueto.com/uploads/arc_teryx.svg',
    alt: 'Arc Teryx'
  },
  {
    src: 'https://assets.mansueto.com/uploads/athletic_brewing.svg',
    alt: 'Athletic Brewing'
  },
  {
    src: 'https://assets.mansueto.com/uploads/calm.svg',
    alt: 'Calm'
  },
  {
    src: 'https://assets.mansueto.com/uploads/crocs.svg',
    alt: 'Crocs'
  },
  {
    src: 'https://assets.mansueto.com/uploads/disney.svg',
    alt: 'Disney'
  },
  {
    src: 'https://assets.mansueto.com/uploads/elf.svg',
    alt: 'ELF'
  },
  {
    src: 'https://assets.mansueto.com/uploads/eventbrite.svg',
    alt: 'Eventbrite'
  },
  {
    src: 'https://assets.mansueto.com/uploads/gap.svg',
    alt: 'GAP'
  },
  {
    src: 'https://assets.mansueto.com/uploads/gloosier.svg',
    alt: 'Gloosier'
  },
  {
    src: 'https://assets.mansueto.com/uploads/guiness.svg',
    alt: 'Guiness'
  },
  {
    src: 'https://assets.mansueto.com/uploads/loewe.svg',
    alt: 'Ioewe'
  },
  {
    src: 'https://assets.mansueto.com/uploads/mcdonalds.svg',
    alt: "McDonald's"
  },
  {
    src: 'https://assets.mansueto.com/uploads/nfl.svg',
    alt: 'NFL'
  },
  {
    src: 'https://assets.mansueto.com/uploads/nike.svg',
    alt: 'Nike'
  },
  {
    src: 'https://assets.mansueto.com/uploads/meals_on_weels.svg',
    alt: 'Meals on Wheels'
  },
  {
    src: 'https://assets.mansueto.com/uploads/panda_express.svg',
    alt: 'Panda Express'
  },
  {
    src: 'https://assets.mansueto.com/uploads/reverb.svg',
    alt: 'Reverb'
  },
  {
    src: 'https://assets.mansueto.com/uploads/sephora.svg',
    alt: 'Sephora'
  },
  {
    src: 'https://assets.mansueto.com/uploads/t_mobile.svg',
    alt: 'T-Mobile'
  },
  {
    src: 'https://assets.mansueto.com/uploads/tubi.svg',
    alt: 'Tubi'
  },
  {
    src: 'https://assets.mansueto.com/uploads/universal.svg',
    alt: 'Universal'
  },
  {
    src: 'https://assets.mansueto.com/uploads/vita_coco.svg',
    alt: 'Vita Coco'
  }
];

export const BtmPastHonereesLogos = () => (
  <div className="btm_past_honerees_logos">
    {pastHonereesLogos.map(item => (
      <img src={item.src} alt={item.alt} key={item.alt} />
    ))}
  </div>
);
